import React, {Component} from 'react';

class Calendly extends Component {
    render(){
        return (
            <section id="calendly">
                <div>
                    <iframe
                        title="calendly"
                        src="https://calendly.com/m-tanner2"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                    />
                </div>
            </section>
        );
    }
}

export default Calendly;
